import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import './About.css';
import axios from 'axios';

const testimonials = [
  {
    name: "Nancy Rizk",
    text: "اقوى كورس حقيقي ساعدني كتير اني اعرف تفاصيل كتيرة اوي كانت معطلة شغلي و اني أحقق بنصح بيه اي .. Amazon دخل اكبر من خلال حد عايز يعمل بيزنس و عايز يحقق دخل اضافي"
  },
  {
    name: "Zak skafi",
    text: "الله يبارك فيكم  وشكرا علي الكورس بصراحة الكورس  وراح احضره  مرة تانية وابلش اطبق اكتر"
  },
  {
    name: "Mazen",
    text: "Such a Great course with helpful information, the whole process is not easy but for sure the course made it much easier, just put in mind you have to be ready with a moderate capital to start with it and if you needed any help they will definitely help you without any hesitations!"
  }
];


const AboutUs = ({ baseUrl }) => {
  let navigate = useNavigate();
  const [numper, setnumper] = useState(0);
  const [countUpRef, inView] = useInView({ triggerOnce: true });
  async function name() {
    await axios.get(`${baseUrl}/api/User/userCount`)
      .then((res) => {
        setnumper(res.data+59)
      })
      .catch((err)=>{
        console.log(err);
      })
  }
useEffect(() => {
  
name()

}, []);
  return (
    <>
      <section className='pattern'>
        <div className='d-sm-flex d-none align-items-center justify-content-between mb-0'>
          <div className='col-6 container'>
            <img className='w-100' src={require('../../Assets/data-analysis.png')} alt="Data Analysis" />
          </div>
          <div className='col-sm-6 col-10 mt-sm-0 mt-2 text-white container'>
            <h1 className='text-start'>تبحث عن بدء أو توسيع أعمالك عبر الأنترنت</h1>
            <h3 className='text-start'>عايز تستثمر وقتك وجهدك في تحقيق ربح من خلال التجارة الألكترونيه؟</h3>
            <h5 className='text-start'>
              تقدم فرصة استثنائية للتفوق في سوق عمل التجارة الألكترونيه FBA Sellzagy
              <br />
              من خلال تمكين الأفراد لإنشاء أعمال تجارية ناجحة عبر منصات التجارة الألكترونيه
              بناء علي خبرة وتجربة حقيقة لكيفية النجاح في هذا النوع من البزنس
              هنا، نقدم لك كل ما تحتاجه لتحقيق أحلامك في التجارة الألكترونيه
            </h5>
          </div>
        </div>
        <div className='d-flex d-sm-none align-items-center justify-content-between mb-0'>
          <div className='col mt-sm-0 mt-2 text-white container py-3'>
            <h3 className='text-center'>تبحث عن بدء أو توسيع أعمالك عبر الأنترنت</h3>
            <h6 className='text-center'>عايز تستثمر وقتك وجهدك في تحقيق ربح من خلال التجارة الألكترونيه؟</h6>
          </div>
        </div>
      </section>

      <section ref={countUpRef} className='container mt-5 text-center  justify-content-around  align-items-stretch flex-row-reverse d-lg-flex d-none '>
        <span>
          <h2 className='text-center'>عدد المشتركين في الكورس </h2>
          <i className='fa fa-user text-main fs-3  me-2'></i>  {inView && <CountUp end={numper} duration={5} className='text-main fs-2 fw-bold' />}
        </span>
      
        <span  >
          <h2 className='text-center'> العاملين في التجارة الألكترونيه حول العالم</h2>
          <span >
            <i className='fa fa-user text-main fs-3  me-2'></i>  {inView && <CountUp end={2640000000} duration={5} className='text-main fs-2 fw-bold' suffix='' />}
          </span>
          <p className='text-secondary'>بحسب احصائيات 2023</p>
        </span>
       
        <span>
          <h2 className='text-center'> ارباح التجارة الألكترونيه حول العالم</h2>
          <i className='fa fa-money-bill-wave  text-main fs-3 me-2'></i> {inView && <CountUp end={6330000000000} duration={5} className='text-main fs-2 fw-bold' prefix='$' />}
          <p className='text-secondary'>بحسب احصائيات 2023</p>
        </span>

      </section>

      <section className='container my-3'>
        <div className='row justify-content-center d-lg-flex d-none'>
          {testimonials.map((testimonial, index) => (
            <div className={`card col-4 mx-auto rounded-0 ${index === 0 ? 'rounded-start-2' : index === testimonials.length - 1 ? 'rounded-end-2' : ''} border-dark`} style={{ maxWidth: '600px' }} key={index}>
              <div className="card-body d-flex flex-column justify-content-start gap-2 text-center">
                <h5 className="card-title mt-3">{testimonial.name}</h5>
                <i className='fa fa-quote-left text-main fs-1'></i>
                <p className="card-text mt-3" style={{ direction: 'rtl' }}>{testimonial.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div id="carouselExample" className="carousel slide d-lg-none" data-bs-ride="carousel">
          <div className="carousel-inner">
            {testimonials.map((testimonial, index) => (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                <div className="card testimonial-card mx-auto" style={{ maxWidth: '500px', height: '300px' }}>
                  <div className="card-body text-center d-flex flex-column justify-content-center gap-2">
                    <i className="bi bi-quote" style={{ fontSize: '2rem', color: '#0d6efd' }}></i>
                    <h5 className="card-title">{testimonial.name}</h5>
                    <i className='fa fa-quote-left text-main fs-1'></i>
                    <p className="card-text" style={{ direction: 'rtl' }}>{testimonial.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>


      <Divider />
      <section className='my-2 row justify-content-center row-gap-4'>
        <div className='col-sm-6 d-flex flex-column justify-content-center align-items-center border-end border-dark'>
          <img src={require('../../Assets/logo.webp')} alt="Logo" className='col-8' />
          <div className='text-center col-12'>
            <button className='hover-btn fw-bold' onClick={() => navigate('/contact')}>3,500 EGP أشترك الأن</button>
          </div>
        </div>
        <div className='col-sm-6 d-flex flex-column justify-content-center align-items-center'>
          <h3 className='d-sm-inline d-none'>تواصل <span className='text-main'>معانا</span></h3>
          <div class="meida-card">
            <a href="https://www.instagram.com/sellzagy?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank' rel='noreferrer' class="socialContainer containerOne">
              <svg class="socialSvg instagramSvg" viewBox="0 0 16 16"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path> </svg>
            </a>

            <a href="https://www.facebook.com/Sellzagy" class="socialContainer containerTwo" target='_blank' rel='noreferrer'>
              <svg class="text-white socialSvg facebook" width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path fill='white' d="M16.75,9H13.5V7a1,1,0,0,1,1-1h2V3H14a4,4,0,0,0-4,4V9H8v3h2v9h3.5V12H16Z"></path></svg>
            </a>

            <a href="https://www.linkedin.com/company/sellzagy/" class="socialContainer containerThree" target='_blank' rel='noreferrer'>
              <svg class="socialSvg linkdinSvg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
            </a>

            <a href="https://www.youtube.com/@sellzagy6307" class="socialContainer containerFour" target='_blank' rel='noreferrer'>
              <svg class="socialSvg" xmlns="ww.w3.org/2000/svg" viewBox="0 0 576 512">
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
            </a>

          </div>
        </div>
      </section>

      <section className='bg-main mt-5 ' style={{ height: '50px', width: '100%' }}></section>
    </>
  );
};

export default AboutUs;
