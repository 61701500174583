import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

const ReviewModal = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onCreate(values);
    };

    return (
        <Modal
            visible={visible}
            title="Add Review"
            okText="Add"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields().then((values) => {
                    form.resetFields();
                    onFinish(values);
                });
            }}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="username"
                    label="Username"
                    rules={[{ required: true, message: 'Please enter username' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Message"
                    rules={[{ required: true, message: 'Please enter message' }]}
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ReviewModal;
