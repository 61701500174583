import { Avatar, Button, Card, List, Skeleton, message } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function AdminHome({ baseUrl }) {
  const [list, setList] = useState([]);
  const [loading, setloading] = useState();
  let navigate = useNavigate()
  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('adminToken'),
    "Content-Type": "application/json",
  };
  async function getUsers() {
    setloading(true)
    axios.get(`${baseUrl}/api/User/UsersList`, { headers })
      .then((response) => {
        setList(response.data.users)
        setloading(false)

      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login')
          message.warning(`This account is open in another device`);

        }
        setloading(false)

      })
  }
  const deleteUser = async (userName, userId) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/User/DeleteUser?Id=${userId}`, { headers });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      message.success(`${userName} deleted successfully`);
      getUsers()
      // Optionally, you can update the state or perform other actions here after successful deletion
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/login')
      }
      message.error('There was a problem with the delete operation');
      console.error('There was a problem with the delete operation:', error);
    }
  };
  useEffect(() => {
    getUsers()
  }, []);

  return (
    <div className='container-fluid pattern py-3'>
      <div className="container">
        <Card>
          <div className='text-end mb-2 gap-2 d-flex justify-content-end'>
            <button className='btn btn-main text-white rounded-5 shadow-sm' onClick={() => navigate('/admin/reviews')}> <i className='fa-regular fa-face-smile' /> Revioes</button>
            <button className='btn btn-main text-white rounded-5 shadow-sm' onClick={() => navigate('/admin/pdf')}> <i className='fa-regular fa-file-lines' /> Resources</button>
            <button className='btn btn-main text-white rounded-5 shadow-sm' onClick={() => navigate('/admin/add-user')}> <i className='fa fa-user-plus' /> Add User</button>
          </div>
          <h4 className='fw mb-3'><span className='text-main'>Users</span> List</h4>
          <List
            className="demo-loadmore-list overflow-auto"
            style={{ height: '60vh' }}

            itemLayout="horizontal"
            dataSource={list}
            loading={loading}
            renderItem={(item) => (
              <List.Item
                actions={[<Button type='link' danger onClick={() => { deleteUser(item.username, item.id) }}> <i className='fa fa-trash'></i></Button>]}
              >
                <List.Item.Meta
                  title={item.username}
                // description={item.email}
                />
                <div className='d-flex justify-content-between  col'>
                  <div>{item.email}</div>
                  <div>{item.phoneNumber}</div>
                </div>
              </List.Item>
            )}
          />
        </Card>
      </div>
    </div>
  )
}
