import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Layout from "./Pages/Layout";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import ProtectedRoute from "./Components/Protected Route/ProtectedRoute";
import Error404 from "./Pages/Errors Handling/Error 404/Error404";
import InternalServerError from "./Pages/Errors Handling/Error 500/InternalServerError";
import About from "./Pages/About-us/About";
import Contact from "./Pages/Contact-us/Contact";
import AdminHome from "./Pages/Admin/AdminHome";
import AddUser from "./Pages/Admin/AddUser";
import AboutUs from "./Pages/About-us/About";
import { useEffect, useState } from "react";
import SplashScreen from "./SplashScreen/SplashScreen";
import UploadPdf from "./Pages/Admin/UploadPdf";
import Reviews from "./Pages/Admin/Reviews";

function App() {
  const baseUrl = "https://sellzagyapi.voyalix.com";
   const [loading, setLoading] = useState(true);
   const [fadeOut, setFadeOut] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000); // Match this with the CSS transition duration
      }, 3000); // Duration of the splash screen

      return () => clearTimeout(timer);
    }, []);
  const routers = createBrowserRouter([
    { path: "login", element: <Login baseUrl={baseUrl} /> },

    {
      element: <Layout />,
      children: [
        {
          index: localStorage.getItem("userToken") ? true : false,
          element: (
            <ProtectedRoute role={0}>
              <Home baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          index: !localStorage.getItem("userToken") ? true : false,
          element: <AboutUs baseUrl={baseUrl} />,
        },
        {
          path: "home",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Home baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },

        {
          path: "about-us",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <About baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "contact",
          element: <Contact baseUrl={baseUrl} />,
        },
      ],
    },
    {
      path: "admin",
      element: <Layout role={1} />,
      children: [
        {
          path: "home",
          element: (
            <ProtectedRoute role={1}>
              <AdminHome baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "reviews",
          element: (
            <ProtectedRoute role={1}>
              {" "}
              <Reviews baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "add-user",
          element: (
            <ProtectedRoute role={1}>
              <AddUser baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "pdf",
          element: (
            <ProtectedRoute role={1}>
              <UploadPdf baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
      ],
    },

    //wrong route handle
    { path: "*", element: <Error404 /> },
    { path: "err-500", element: <InternalServerError /> },
  ]);

  return<>
  <div className={`${loading?'vh-100 overflow-y-hidden':''}`}>
   {loading ? <SplashScreen fadeOut={fadeOut} /> :''}
  <RouterProvider router={routers} />
  </div>
  </>
}

export default App;
