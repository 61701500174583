// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SplashScreen.css */
.splash-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    background: linear-gradient(135deg, #007BFF, #00BFFF, #3498db);
    /* Blue gradient */
    opacity: 1;
    transition: opacity .5s ease-out;
    position: absolute;
    z-index: 10;
    overflow: hidden;
    /* animation: fadeIn .5s ease-in-out; */
}

.splash-screen.fade-out {
    opacity: 0;
    animation: fadeOut 3s ease-in-out;
}

.splash-logo {
    width: 200px;
    height: auto;
    animation: logoMove 3s infinite ease-in-out;
}

/* Keyframes for fade-in and fade-out */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Keyframes for logo movement */
@keyframes logoMove {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

/* Responsive design */
@media (max-width: 600px) {
    .splash-logo {
        width: 150px;
    }
        .splash-screen{
            width: 100vw;
        }
}`, "",{"version":3,"sources":["webpack://./src/SplashScreen/SplashScreen.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,UAAU;IACV,SAAS;IACT,8DAA8D;IAC9D,kBAAkB;IAClB,UAAU;IACV,gCAAgC;IAChC,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,uCAAuC;AAC3C;;AAEA;IACI,UAAU;IACV,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,2CAA2C;AAC/C;;AAEA,uCAAuC;AACvC;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA,gCAAgC;AAChC;;IAEI;;QAEI,mBAAmB;IACvB;;IAEA;QACI,qBAAqB;IACzB;AACJ;;AAEA,sBAAsB;AACtB;IACI;QACI,YAAY;IAChB;QACI;YACI,YAAY;QAChB;AACR","sourcesContent":["/* SplashScreen.css */\n.splash-screen {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100vw;\n    padding: 0;\n    margin: 0;\n    background: linear-gradient(135deg, #007BFF, #00BFFF, #3498db);\n    /* Blue gradient */\n    opacity: 1;\n    transition: opacity .5s ease-out;\n    position: absolute;\n    z-index: 10;\n    overflow: hidden;\n    /* animation: fadeIn .5s ease-in-out; */\n}\n\n.splash-screen.fade-out {\n    opacity: 0;\n    animation: fadeOut 3s ease-in-out;\n}\n\n.splash-logo {\n    width: 200px;\n    height: auto;\n    animation: logoMove 3s infinite ease-in-out;\n}\n\n/* Keyframes for fade-in and fade-out */\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n\n    to {\n        opacity: 1;\n    }\n}\n\n@keyframes fadeOut {\n    from {\n        opacity: 1;\n    }\n\n    to {\n        opacity: 0;\n    }\n}\n\n/* Keyframes for logo movement */\n@keyframes logoMove {\n\n    0%,\n    100% {\n        transform: scale(1);\n    }\n\n    50% {\n        transform: scale(1.2);\n    }\n}\n\n/* Responsive design */\n@media (max-width: 600px) {\n    .splash-logo {\n        width: 150px;\n    }\n        .splash-screen{\n            width: 100vw;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
