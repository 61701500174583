import React from 'react';
import './SplashScreen.css';

const SplashScreen = ({ fadeOut }) => {
    return (
        <div className={`splash-screen ${fadeOut ? 'fade-out' : ''}`}>
            <img src={require("./logo.png")} alt="Logo" className="splash-logo" />
        </div>
    );
};

export default SplashScreen; 
