import React from 'react';
import { Form, Input, Button, message, Card } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddUser = ({ baseUrl }) => {
    const [form] = Form.useForm();
    const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('adminToken'),
        "Content-Type": "application/json",
    };

    let navigate = useNavigate()

    const validatePassword = (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Please input your password!'));
        }
        if (!/[A-Z]/.test(value)) {
            return Promise.reject(new Error('Passwords must have at least one uppercase letter.'));
        }
        if (!/[0-9]/.test(value)) {
            return Promise.reject(new Error('Passwords must have at least one digit.'));
        }
        if (!/[^a-zA-Z0-9]/.test(value)) {
            return Promise.reject(new Error('Passwords must have at least one non-alphanumeric character.'));
        }
        return Promise.resolve();
    };

    const onFinish = async (values) => {
        try {
            const response = await axios.post(`${baseUrl}/api/User/AddUser`, values, { headers })
            if (response.data.statusCode === 201) {
                message.success('User added successfully!');
                form.resetFields();
                navigate('/admin/home')

            }
        } catch (error) {
            console.error('There was an error adding the user!', error);
            if (error.response.status === 400) {
                message.error(error?.response?.data?.errors[0]);
            }
           else if (error.response.status === 401) {
                navigate('/login')
                message.warning(`This account is open in another device`);

            }
            else {
                message.error('Failed to add user. Please try again.');
            }
        }
    };

    return (
        <div className=' d-flex justify-content-center pattern py-4 pt-5'>
            <Card className='rounded-4' style={{ width: 600 }}>
                <h2 style={{ textAlign: 'center' }}><span className='text-main'>Add</span> User</h2>
                <Form
                    requiredMark={false}
                    form={form}
                    name="add_user"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'Please enter a valid email!' }
                        ]}
                    >
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ validator: validatePassword }]}
                    >
                        <Input.Password size='large' />
                    </Form.Item>

                    <Form.Item>
                        <div className='text-end'>
                            <Button className='me-2' onClick={()=>{navigate('/admin/home')}}>
                                Back
                            </Button>
                            <Button type="primary" htmlType="submit" >
                                Add User
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default AddUser;
