import React from 'react'
import Navbar from '../Components/NavBar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'

export default function Layout({role}) {
  return (
    <div className=' min-vh-100 overflow-x-hidden  w-100'>
      <Navbar role={role}/>
      <Outlet></Outlet>
      {/* <Footer /> */}
      
    </div>
  )
}
