import './home.css'
import React, { useEffect, useState } from 'react';
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer';
import { Collapse, Divider, List, Modal, Skeleton, Spin, message } from 'antd';
import axios from "axios";
import ListSteps from './ListSteps';
import { useNavigate } from 'react-router-dom';
export default function Home({ baseUrl }) {
  const [content, setcontent] = useState([]);
  const [videoId, setVideoId] = useState();
  const [data, setData] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [globalCurrent, setGlobalCurrent] = useState({ chapter: 0, step: -1 });
  const [Pdfs, setPdfs] = useState([{}, {}, {}, {}]);
  const [title, settitle] = useState();
  const [showmodal, setshowmodal] = useState();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate()
  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('userToken'),
    "Content-Type": "application/json",
  };

  useEffect(() => {
    // Add the class to the body element
    document.body.classList.add('pattern');

    // Clean up and remove the class from the body element when the component unmounts
    return () => {
      document.body.classList.remove('pattern');
    };
  }, []);

  useEffect(() => {
    getPdfs()
    renderContent();

  }, [content, globalCurrent]);

  async function getVideos() {
    try {
      const response = await axios.get(`${baseUrl}/api/Youtube/GetVideos`, { headers });
      setcontent(response?.data);

    } catch (err) {
      if (err.response.status === 401) {
        navigate('/login')
        message.warning(`This account is open in another device`);


      }
    }
  }

  async function getPdfs(id) {
setloading(true)
    try {
      const response = await axios.get(`${baseUrl}/api/Title/GetTitlesPdfList?TitleId=${id}`, { headers });
      setPdfs(response?.data);
      setloading(false)

    }
    catch (err) {
      if (err.response.status === 401) {
        navigate('/login')
        message.warning(`This account is open in another device`);
      }
      if (err.response.status === 404) {
        setPdfs()
      }
      setloading(false)
    }
  }

  async function renderContent() {
    try{    
      setData(Object.entries(content).map(([key, value], index) => {
        return {
          label:key,
           key: index,
          children: (
            <ListSteps
              list={value.videos}
              setVideoId={setVideoId}
              currentChapter={currentChapter}
              globalCurrent={globalCurrent}
              setGlobalCurrent={setGlobalCurrent}
              chapterIndex={index}
            />
          ),
          value: value,
          extra: <button className='btn btn-primary text-white rounded-5 btn-sm shadow-sm' onClick={(e) => handleShowContent(e, value.titleId,key) }><i className='fa-regular fa-file-lines '></i></button> 
  
        }
      }))
    }catch{
    }
  }

  const handleShowContent = (e, id,title) => {
    e.stopPropagation();
    getPdfs(id)
    setshowmodal(true)
    settitle(title)
  }
 function handleCancel(params) {
   setshowmodal(false)
setPdfs([{},{},{},{}])
 } 
  useEffect(() => {
    getVideos();
  }, []);

  return (
    <div className="pt-5" >
      <div className="container-fluid mb-2">
        <div className='d-flex flex-md-row row-gap-4 flex-column-reverse justify-content-around '>
          <div className='col-md-4 col-12 d-flex justify-content-center align-items-center' >
            <div className='w-100 p-0'>
              {content.length < 1 ?
                <Spin fullscreen /> :
                <Collapse
                  items={data}
                  accordion
                  className='bg-white'
                  defaultActiveKey={currentChapter}
                  onChange={(key) => setCurrentChapter(Number(key))}
                
                />
              }
              <Divider className='mb-2 mt-1' />
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <VideoPlayer videoId={videoId} baseUrl={baseUrl} />
          </div>
        </div>
      </div>


      <Modal title={title + ' resources'} open={showmodal} footer={false} onCancel={handleCancel}>
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={Pdfs}
          renderItem={(item) => (
            <Skeleton avatar title={false} loading={loading} active>
            <List.Item
              actions={[<a className='link-primary text-decoration-none' href={`https://sellzagyapi.voyalix.com/api/Storage/GetPDFById?Id=${item?.pdfID}`} rel='noreferrer'  target='_blank'><i class="fa-solid fa-download fa-lg"></i></a>, ]}
            >

                <List.Item.Meta
                  avatar={<i className='fa fa-file-pdf text-primary'/>}
                title={item?.name}
                  description={item?.description}
                />
            </List.Item>
              </Skeleton>
          )}
        />
      </Modal>


    </div>
  );
}
