import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
export default function Login({ baseUrl }) {
    let navigate = useNavigate()
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        localStorage.clear();

    }, [])


    const validateEmail = (rule, value) => {
        if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            return Promise.reject('Please enter a valid email address');
        } else {
            return Promise.resolve();
        }
    };

    const onFinish = (values) => {
        sendDataToLogin(values);
    };

    async function sendDataToLogin(values) {
        setIsLoading(true)
        await axios.post(`${baseUrl}/api/User/Login`,values)
            .then((response) => {
                if (response.data.data.role === "admin") {
                    localStorage.setItem('adminToken', response.data.data.token)
                    navigate('/admin/home')
                }
                else {
                    localStorage.setItem('userToken', response.data.data.token)
                    navigate('/home')
                }

                setIsLoading(false)
            }).catch((error) => {
                    setError("Invalid email or password");
                setIsLoading(false)
            })
    }



    return <>
        <div className="container-fluid d-md-block d-none ">
            <div className="row no-gutter">
                {/* The content half */}
                <div className="login  col-xl-4 col-lg-5 col-6  d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-9 mx-auto">
                                <div style={{ color: '#1A1A1A', fontSize: 26, fontWeight: '700', letterSpacing: 0.20, wordWrap: 'break-word' }}>Welcome <span className='text-main'>Back</span></div>
                                <p className=" text-secondary">Enter your credential below to get started</p>
                                <Form onFinish={onFinish} initialValues={{ remember: false }}>
                                    <div className="">
                                        <div>
                                            {error ? (
                                                <div className="alert alert-danger fs-6 text-center">{error}</div>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                        <Form.Item
                                            name="email"
                                            className="custom-form-item"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your email!',
                                                },
                                                { validator: validateEmail },
                                            ]}
                                        >
                                            <Input
                                                // style={{ borderColor: '#5B0960', boxShadow: '0 0 0 2px #5B0960' }}
                                                size="large"
                                                className='custom-form-item py-2'
                                                placeholder="Email" prefix={<i className="fa-solid t fa-lg fa-envelope me-2"></i>} onChange={() => setError('')} />
                                        </Form.Item>
                                    </div>
                                    <div className="">
                                        <Form.Item
                                            name="password"
                                            rules={[{ required: true, message: 'Please enter your password' }]}
                                        >
                                            <Input.Password size="large" className='py-2' placeholder="Password" prefix={<i className="fa-solid t fa-lg fa-lock me-2"></i>} onChange={() => setError('')} />
                                        </Form.Item>
                                            <p className='text-secondary'>Your password must have at least 8 characters</p>
                                    </div>
                                 
                                    <Form.Item>
                                        <button className='w-100 btn-main btn btn-lg text-white py-2 fw-bold' type="primary" htmlType="submit">
                                            {isLoading ? "Loading..." : "Sign In"}
                                        </button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  The image  */}
                <div className="col-xl-8 col-lg-7 col-6 p-0 d-none d-md-flex bg-image-login shadow position-relative">
                    <div className='w-100 h-100 bg-black bg-opacity-10'>
                        {/* float logo in the top left corner */}
                        <div className='position-absolute bg-light bg-opacity-50 rounded-5 col-3' style={{top:15,right:30}}>
                            <img src={require('../../Assets/logo.webp')} alt="" className='w-100'/>
                        </div>

                    </div>
                </div>
              

            </div>
        </div>
        <div className="container-fluid d-md-none d-block ">
            <div className="row no-gutter">
                {/*  The image half  */}
                <div className="p-0 bg-image-login ">
                    <div className='bg-black bg-opacity-25 min-vh-100 w-100 container  d-flex justify-content-center align-items-center'> 
                            <div className="  d-flex align-items-center  card p-4 rounded-4 shadow-sm">

                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10 col-xl-9 mx-auto">
                                            <div className='mb-2' style={{ color: '#1A1A1A', fontSize: 26, fontWeight: '700', letterSpacing: 0.20, wordWrap: 'break-word' }}>Welcome <span className='text-main'>Back</span></div>
                                        <p className=" text-secondary">Enter your credential to get started</p>
                                            <Form onFinish={onFinish} initialValues={{ remember: false }}>
                                                <div className="">
                                                    <div>
                                                        {error ? (
                                                            <div className="alert alert-danger fs-6 text-center">{error}</div>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                    <Form.Item
                                                        name="email"
                                                        className="custom-form-item"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your email!',
                                                            },
                                                            { validator: validateEmail },
                                                        ]}
                                                    >
                                                        <Input
                                                            // style={{ borderColor: '#5B0960', boxShadow: '0 0 0 2px #5B0960' }}
                                                            size="large"
                                                            className='custom-form-item py-2'
                                                            placeholder="Email" prefix={<i className="fa-solid t fa-lg fa-envelope me-2"></i>} onChange={() => setError('')} />
                                                    </Form.Item>
                                                </div>
                                                <div className="">
                                                    <Form.Item
                                                        name="password"
                                                        rules={[{ required: true, message: 'Please enter your password' }]}
                                                    >
                                                        <Input.Password size="large" className='py-2' placeholder="Password" prefix={<i className="fa-solid t fa-lg fa-lock me-2"></i>} onChange={() => setError('')} />
                                                    </Form.Item>
                                                    <p className='text-secondary'>password must have at least 8 characters</p>
                                                </div>

                                                <Form.Item>
                                                    <button className='w-100 btn-main btn btn-lg text-white py-2 fw-bold' type="primary" htmlType="submit">
                                                        {isLoading ? "Loading..." : "Sign In"}
                                                    </button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                </div>
                {/* The content half */}
           


            </div>

    </>
}
