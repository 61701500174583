import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';

export default function ListSteps({ list, setVideoId, currentChapter, globalCurrent, setGlobalCurrent, chapterIndex }) {
    const [current, setCurrent] = useState(null);

    useEffect(() => {
        setCurrent(null);
    }, [currentChapter]);

    function handleChange(step) {
        setCurrent(step);
        setGlobalCurrent({ chapter: chapterIndex, step });
        setVideoId(getYouTubeVideoId(list[step]?.link));
    }
    function getYouTubeVideoId(url) {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    const items = list?.map((item, index) => ({
        ...item,
        status: (chapterIndex < globalCurrent.chapter || (chapterIndex === globalCurrent.chapter && index <= globalCurrent.step)) ? 'finish' : 'wait',
    }));

    return (
        <Steps
            style={{ maxHeight: '25vh' }}
            className='overflow-auto'
            direction="vertical"
            size="small"
            onChange={(e) => handleChange(e)}
            current={current}
            items={items}
        />
    );
}
