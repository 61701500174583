import React, { useEffect, useState } from 'react';
import { Button, List, message, Skeleton } from 'antd';
import ReviewModal from './ReviewModal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Reviews = ({ baseUrl }) => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [expandedReviews, setExpandedReviews] = useState({});
    const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('adminToken'),
        "Content-Type": "application/json",
    };
    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        // Add the class to the body element
        document.body.classList.add('pattern');

        // Clean up and remove the class from the body element when the component unmounts
        return () => {
            document.body.classList.remove('pattern');
        };
    }, []);

    useEffect(() => {
        getReviews();
    }, []);

    const getReviews = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${baseUrl}/api/Review/GetAllReview`);
            setReviews(response.data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
            message.error('Failed to fetch reviews. Please try again.');
            if (error?.response?.status === 401) {
                navigate('/login');
                message.warning(`This account is open in another device`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreate = async (values) => {
        try {
            const response = await axios.post(`${baseUrl}/api/Review/AddReview`, values,{headers});
            console.log('Review added:', response.data);
            setVisible(false); // Close the modal after successful submit
            message.success('Review added successfully!');
            getReviews(); // Refresh the reviews list
        } catch (error) {
            console.error('Error adding review:', error);
            message.error('Failed to add review. Please try again.');
            if (error?.response?.status === 401 ||error?.response?.status === 403) {
                message.warning(`This account is open in another device`);
                navigate('/login');
                
            }
        }
    };

    const handleDelete = async (id, name) => {
        try {
            const response = await axios.delete(`${baseUrl}/api/Review/DeleteReview?ReviewId=${id}`,{headers});
            console.log('Review deleted:', response.data);
            message.success(`Review ${name} deleted successfully!`);
            getReviews(); // Refresh the reviews list
        } catch (error) {
            console.error('Error deleting review:', error);
            message.error('Failed to delete review. Please try again.');
        }
    };

    const toggleExpand = (reviewId) => {
        setExpandedReviews({
            ...expandedReviews,
            [reviewId]: !expandedReviews[reviewId],
        });
    };

    const renderMessageContent = (message, reviewId) => {
        const maxLength = 165; // Example max length before showing "read more"

        if (!expandedReviews[reviewId] && message.length > maxLength) {
            return (
                <>
                    {`${message.slice(0, maxLength)} ... `}
                    <Button type="link"  size='small' onClick={() => toggleExpand(reviewId)}>Read more</Button>
                </>
            );
        } else if (expandedReviews[reviewId] || message.length <= maxLength) {
            return (
                <>
                    {message}
                    {expandedReviews[reviewId] && (
                        <Button type="link" size='small'  onClick={() => toggleExpand(reviewId)}>Read less</Button>
                    )}
                </>
            );
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '80vh' }}>
            <div className='shadow-sm row gap-3 card p-3' style={{ width: 800 }}>
                <h3 className='col text-center'>Course <span className='text-main'> Reviews</span></h3>
                <div className='col d-flex justify-content-end align-items-center'>
                    <button className='btn btn-main text-white rounded-5 shadow-sm' onClick={showModal}>
                        <i className='fa fa-plus-circle' /> Add Review
                    </button>
                </div>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={reviews}
                    renderItem={(item) => (
                        <Skeleton avatar title={false} active loading={isLoading}>
                            <List.Item
                                actions={[
                                    <Button type='link' size='' className='' onClick={(e) => handleDelete(item.revewId, item.username)}>
                                        <i className='fa fa-trash text-danger'></i>
                                    </Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<i className='fa-regular fa-user-circle text-primary fa-lg' />}
                                    title={item?.username}
                                    description={renderMessageContent(item?.message, item.revewId)}
                                />
                            </List.Item>
                        </Skeleton>
                    )}
                />
            </div>
            <ReviewModal
                visible={visible}
                onCreate={handleCreate}
                onCancel={handleCancel}
            />
        </div>
    );
};

export default Reviews;
