import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Input, List, Modal, Select, Skeleton, Upload, message } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function UploadPdf({ baseUrl }) {
    const [pdfId, setpdfId] = useState();
    const [titleId, settitleId] = useState(null);
    const [Titles, setTitles] = useState([{}, {}, {}, {}]);
    const [name, setname] = useState(null);
    const [des, setdes] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [list, setlist] = useState();
    const [title, settitle] = useState();
    const [isloading, setisloading] = useState();
    const [showmodal, setshowmodal] = useState();
    const [Pdfs, setPdfs] = useState([{}, {}, {}, {}]);
    const [openmodal, setopenmodal] = useState(false);

    let navigate = useNavigate()
    const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('adminToken'),
    };

    const props = {
        name: 'File',
        action: `${baseUrl}/api/Storage/UploadPDF`,
        headers: headers,
        onChange(info) {
            setlist(info.fileList)
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setpdfId(info?.file?.response?.id)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                if (info?.file?.error?.status === 401) {
                    navigate('/login')
                    message.warning(`This account is open in another device`);

                }
            }
        },
    };

    async function getTitles(params) {
        setisloading(true)
        await axios.get(`${baseUrl}/api/Title/GetTitlesList`, { headers })
            .then((res) => {
                setTitles(res?.data?.titles);
                console.log(res);

                setisloading(false)
            })
            .catch((err) => {
                setisloading(false)
                if (err?.response?.status === 401) {
                    navigate('/login')
                    message.warning(`This account is open in another device`);
                }
            })
    }
    useEffect(() => {
        getTitles();

    }, []);

    useEffect(() => {
        // Add the class to the body element
        document.body.classList.add('pattern');

        // Clean up and remove the class from the body element when the component unmounts
        return () => {
            document.body.classList.remove('pattern');
        };
    }, []);

    async function addContent() {
        setErrorMessage(null)
        if (titleId === null) {
            setErrorMessage('Please select title');
            return
        }
        if (pdfId === null) {
            setErrorMessage('Please select pdf');
            return
        }
        if (name === null || name?.length < 0) {
            setErrorMessage('Please enter name');
            return
        }

        const data = {
            "name": name,
            "description": des,
            "pdfID": pdfId,
            "titleId": titleId
        }
        axios.post(`${baseUrl}/api/Title/AddPdfToTitle`, data, { headers })
            .then((res) => {
                message.success(`PDF Added successfully`);
                setname(null)
                setdes('')
                setpdfId(null)
                settitleId(null)
                setlist(null)
                setErrorMessage(null)
            })
            .catch((err) => {
                message.error(err.response.data[0]);

            })
    }
    function handleCancel(params) {
        setopenmodal(false)
        setshowmodal(false)
        setPdfs([{}, {}, {}, {}])
        settitleId(null)

    }
    async function getPdfs(id) {
        try {
            const response = await axios.get(`${baseUrl}/api/Title/GetTitlesPdfList?TitleId=${id}`, { headers });
            setPdfs(response?.data);

        }
        catch (err) {
            if (err.response.status === 401) {
                navigate('/login')
                message.warning(`This account is open in another device`);
            }
            if (err.response.status === 404) {
                setPdfs()
            }
        }
    }
    const handleShowContent = (id, title) => {
        getPdfs(id)
        settitleId(id)
        setshowmodal(true)
        settitle(title)
    }
    async function handleDelete(id) {
        axios.delete(`${baseUrl}/api/Title/DeleteTitle?Id=${id}`, { headers })
            .then((res) => {
                message.success(`PDF Deleted successfully`);
                getPdfs(titleId)
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    navigate('/login')
                    message.warning(`This account is open in another device`);
                }
                else {

                    message.error(err.response.data[0]);
                }
            })
    }
    return (
        <div className='d-flex justify-content-center align-items-center ' style={{ height: '80vh' }}>
            <div className='shadow-sm row gap-3 card p-3' style={{ width: 600 }} >
                <h3 className='col text-center'>Course <span className='text-main'> Resources</span></h3>
                <div className='col d-flex justify-content-end align-items-center '>
                    <button className='btn btn-main btn-sm text-white rounded-5 shadow-sm' onClick={() => setopenmodal(true)}> <i className='fa-regular fa-file-pdf' /> Uplaod PDF</button>
                </div>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={Titles}
                    renderItem={(item) => (
                        <Skeleton avatar title={false} active loading={isloading}>
                            <List.Item
                                actions={[<Button type='link' size='' className='' onClick={(e) => handleShowContent(item.id, item.name)}><i className='fa-regular fa-eye fa-lg'></i></Button>,]}
                            >

                                <List.Item.Meta
                                    avatar={<i className='fa fa-list text-primary' />}
                                    title={item?.name}
                                    description={item?.description}
                                />
                            </List.Item>
                        </Skeleton>
                    )}
                />
            </div>
            <Modal open={openmodal} okText='Add PDF' onOk={addContent} onCancel={handleCancel} centered closeIcon={false} >
                <h3 className='col text-center'>Upload <span className='text-main'> PDF</span></h3>
                <div>
                    <label htmlFor="select" className='fw-semibold'>Choose chapter</label>
                    <br />
                    <Select id='select' size='large' className='w-100' placeholder='Chapters' onChange={(e) => { settitleId(e) }} value={titleId}>
                        {
                            Titles?.map((title) => {
                                return (
                                    <Select.Option value={title?.id}>{title?.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </div>
                <div>
                    <label htmlFor="name" className='fw-semibold'>Content name</label>
                    <br />
                    <Input className='' id='name' size='large' onChange={(e) => { setname(e.target.value) }} value={name} />
                </div>
                <div>
                    <label htmlFor="des" className='fw-semibold'>Content description <span className='text-secondary'>(optional)</span></label>
                    <br />
                    <TextArea className='' id='des' autoSize={{ minRows: 3, maxRows: 5 }} size='large' value={des} onChange={(e) => { setdes(e.target.value) }} />
                </div>
                <div>
                    <label htmlFor="upload" className='fw-semibold'>Upload your pdf</label>
                    <br />
                    <Upload fileList={list}{...props} id='upload' className='' accept='.pdf , .xlsx , .xls' maxCount={1}>
                        <Button size='large' type='default' icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
                <div className='w-100 my-2'>
                    {errorMessage !== null && (
                        <Alert
                            className=""
                            closable
                            type="error"
                            message={errorMessage}
                            onClose={() => { setErrorMessage(null) }}
                        ></Alert>
                    )}

                </div>

            </Modal>

            <Modal title={title + ' resources'} centered open={showmodal} footer={false} onCancel={handleCancel}>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={Pdfs}
                    renderItem={(item) => (
                        <List.Item
                            actions={[<a className='link-primary text-decoration-none' href={`https://sellzagyapi.voyalix.com/api/Storage/GetPDFById?Id=${item?.pdfID}`} rel='noreferrer' target='_blank'><i class="fa-solid fa-download fa-lg"></i></a>,
                            <a className='link-primary text-decoration-none ' onClick={() => { handleDelete(item?.id) }}><i class="fa-solid fa-trash fa-lg text-danger"></i></a>,

                            ]}
                        >

                            <List.Item.Meta
                                avatar={<i className='fa fa-file-pdf text-primary' />}
                                title={item?.name}
                                description={item?.description}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </div>
    )
}
