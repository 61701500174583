import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './VideoPlayer.css'
export default function VideoPlayer({ baseUrl, preview, videoId}) {
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [show, setShow] = useState(false);
    const [canPlay, setCanPlay] = useState(false);
    const [isMuted, setIsMuted] = useState(true); // State to manage muted status
    const [endByte, setendByte] = useState(200); // State to manage muted status
    const handlePlayPause = () => {
        if (!canPlay) {
            return; // Do nothing if the video cannot be played yet
        }

        try {
            if (isPlaying) {
                videoRef.current.play().then(() => setIsPlaying(false)).catch(err => console.error(err));
                setIsPlaying(false);
            } else {
                videoRef.current.play().then(() => setIsPlaying(true)).catch(err => console.error(err));
            }
        } catch (error) {
            setIsPlaying(false);
        }
    };

    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
        if (newVolume > 0) { setIsMuted(false) }
        else { setIsMuted(true) }
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleSeek = (event) => {
        const newTime = event.target.value;
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
        setendByte(pre => pre + pre)
        setCanPlay(true); // Video is ready to be played
        videoRef.current.play().then(() => setIsPlaying(true)).catch(err => console.error(err));
    };

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {

        } else {
            document.exitFullscreen();
        }
    };

    const handleMuteToggle = () => {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };
    const videoSources = [
        { quality: '360p', url: 'path/to/360p-video.mp4' },
        { quality: '480p', url: 'path/to/480p-video.mp4' },
        { quality: '720p', url: 'path/to/720p-video.mp4' },
        { quality: '1080p', url: 'path/to/1080p-video.mp4' },
    ];
    useEffect(() => {
        setCurrentTime(0);
        setIsPlaying(false);
        setCanPlay(false); // Reset canPlay when videoId changes
    }, [videoId]);

    return (
        <>
            {/* <div class="customCheckBoxHolder d-flex justify-content-center">

                <input class="customCheckBoxInput low" id="cCB1" name="tabs" type="radio" />
                <label class="customCheckBoxWrapper low" for="cCB1">
                    <div class="customCheckBox low">
                        <div class="inner">low</div>
                    </div>
                </label>

                <input class="customCheckBoxInput medium" id="cCB2" name="tabs" type="radio" />
                <label class="customCheckBoxWrapper medium" for="cCB2">
                    <div class="customCheckBox">
                        <div class="inner">Mediem</div>
                    </div>
                </label>

                <input class="customCheckBoxInput hight" id="cCB3" name="tabs" type="radio" />
                <label class="customCheckBoxWrapper hight" for="cCB3">
                    <div class="customCheckBox">
                        <div class="inner">Hight</div>
                    </div>
                </label>

            </div> */}
           
            <div className="video-player position-relative rounded-4 overflow-hidden" ref={containerRef} onMouseEnter={() => { setShow(true) }} onMouseLeave={() => { setShow(false) }}>
                {
                    videoId ? 
                        <iframe
                            className="video"
                            src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&showinfo=1&controls=1&rel=0&color=white`}

                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded YouTube Video"

                        ></iframe>
                :
                 ''
                }
              
            </div>
        </>
    );
}
