import React from 'react'
import { Link } from 'react-router-dom'
export default function Navbar({ role }) {
    return <>

        {/* small devices nav */}
        <nav className="navbar navbar-expand-lg bg-white d-sm-none px-3" >
            <span className="navbar-brand w-25" ><img src={require('./logo.webp')} className='w-100' alt="" /></span>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    {role === 1 ?
                        <div className="nav-item gap-4">
                            <Link className="text-decoration-none d-flex align-items-center  fw-semibold text-danger d-flex align-items-center gap-1" to={'/login'} ><i className='fa fa-sign-out  me-0 my-0'></i> Log-out</Link>
                        </div>
                        :
                        localStorage.getItem("userToken") ?
                            <div className="nav-item gap-4 px-3">
                                <Link className="nav-link  fw-semibold" to={'/home'}>Home</Link>
                                {/* <Link className="nav-link  fw-semibold" to={'/about-us'}>About Us</Link> */}
                                <Link className="nav-link fw-semibold" to={'/contact'}>Contact us</Link>
                                <Link className="text-decoration-none d-flex align-items-center  fw-semibold text-danger d-flex align-items-center gap-1" to={'/login'} ><i className='fa fa-sign-out  me-0 my-0'></i> Log-out</Link>

                            </div>
                            :
                            <div className="nav-item gap-4 px-3">
                                <Link className="nav-link  fw-semibold" to={'/'}>Home</Link>
                                {/* <Link className="nav-link  fw-semibold" to={'/about-us'}>About Us</Link> */}
                                <Link className="nav-link fw-semibold" to={'/contact'}>Contact us</Link>
                                <Link className="nav-link d-flex align-items-center gap-1  fw-semibold " to={'/login'} ><i className='fa fa-sign-in me-0 my-0'></i>Log-in</Link>

                            </div>

                    }
                </ul>
            </div>
        </nav>

        {/* large devices nav */}
        <nav className="navbar navbar-expand-lg bg-white d-none d-sm-inline-block container-fluid" >
            <div className='d-flex justify-content-between align-items-center w-100 container-fluid'>
            <span className="col-md-2 col-3" ><img src={require('./logo.webp')} className='w-75' alt="" /></span>
          
                    {role === 1 ?
                        <div className="d-flex gap-4">
                            <Link className="text-decoration-none d-flex align-items-center  fw-semibold text-danger d-flex align-items-center gap-1" to={'/login'} ><i className='fa fa-sign-out  me-0 my-0'></i> Log-out</Link>
                        </div>
                        :
                        localStorage.getItem("userToken") ?
                            <div className="d-flex gap-4 px-3">
                                <Link className="nav-link  fw-semibold" to={'/home'}>Home</Link>
                                {/* <Link className="nav-link  fw-semibold" to={'/about-us'}>About Us</Link> */}
                                <Link className="nav-link fw-semibold" to={'/contact'}>Contact us</Link>
                                <Link className="text-decoration-none d-flex align-items-center  fw-semibold text-danger d-flex align-items-center gap-1" to={'/login'} ><i className='fa fa-sign-out  me-0 my-0'></i> Log-out</Link>

                            </div>
                            :
                            <div className="d-flex gap-4 px-3">
                                <Link className="nav-link  fw-semibold" to={'/'}>Home</Link>
                                {/* <Link className="nav-link  fw-semibold" to={'/about-us'}>About Us</Link> */}
                                <Link className="nav-link fw-semibold" to={'/contact'}>Contact us</Link>
                                <Link className="nav-link d-flex align-items-center gap-1  fw-semibold " to={'/login'} ><i className='fa fa-sign-in me-0 my-0'></i>Log-in</Link>

                            </div>

                    }
            </div>
        </nav>
    </>
}
