import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { message } from 'antd';
import './Contact.css'
const Contact = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    message: ''
  });
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_2a549na', // Your EmailJS Service ID
      'template_hrq47ub', // Your EmailJS Template ID
      form.current,
      '-9ubbS5Vh4VDBLaB2' // Your EmailJS Public Key
    )
      .then((response) => {
        message.success('Your message has been sent successfully!');
      })
      .catch((err) => {
        console.error('FAILED...', err);
        message.error('Failed to send your message. Please try again later.');
      });

    setFormData({
      user_name: '',
      user_email: '',
      user_phone: '',
      message: ''
    });
  };

  return (

    <div className='d-flex justify-content-center align-items-center form_main '>
      <div action="" className=" d-flex justify-content-around align-items-center w-100 ">
        <form className='col-sm-6 col-12' ref={form} onSubmit={handleSubmit}>
          <p className="heading">Contact-us</p>


          <div className="inputContainer">
            <input
              type="text"
              id="name"
              name="user_name"
              value={formData.user_name}
              onChange={handleChange}
              required
              className="inputField"
              placeholder='Name'
            />

          </div>

          <div className="inputContainer">
            <input
              type="email"
              id="email"
              name="user_email"
              value={formData.user_email}
              onChange={handleChange}
              required
              className="inputField"
              placeholder='E-mail'
            />


          </div>

          <div className="inputContainer">
            <input
              type="tel"
              id="phone"
              name="user_phone"
              value={formData.user_phone}
              onChange={handleChange}
              required
              className="inputField"
              placeholder='Phone'
            />
         
          </div>
          
          <div className="inputContainer">
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder="Message"
              className="inputField fixed-text-area"
              
            ></textarea>
          </div>

          <button id="button">Submit</button>

        </form>
        <div className='col-4 d-sm-inline d-none'>
          <img className='w-100' src={require('../../Assets/contact.png')} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
