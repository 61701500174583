import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute(props) {
    if (props.role === 1 && !localStorage.getItem('adminToken'))
        return <Navigate to='/login' />
    else if (props.role === 0 && !localStorage.getItem('userToken'))
        return <Navigate to='/login' />
    else
        return props.children
}
