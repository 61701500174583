// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pattern{
  

            background-color: #1da1f2 !important;
            background-image: radial-gradient(rgba(255, 255, 255, 0.171) 2px, transparent 0) !important;
            background-size: 30px 30px !important;
            background-position: -5px -5px !important
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/home.css"],"names":[],"mappings":"AAAA;;;YAGY,oCAAoC;YACpC,2FAA2F;YAC3F,qCAAqC;YACrC;AACZ","sourcesContent":[".pattern{\n  \n\n            background-color: #1da1f2 !important;\n            background-image: radial-gradient(rgba(255, 255, 255, 0.171) 2px, transparent 0) !important;\n            background-size: 30px 30px !important;\n            background-position: -5px -5px !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
